body {
  background-color: #f8f8f8;
}

#wrapper {
  width: 100%;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff;
}

@media(min-width:768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7;
  }
}