fieldset {
  border: 1px solid #ccc;
  margin: 15px 0;
  min-width: 0;
  padding: 0 10px 5px;
}


legend {
  border-bottom: 0;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 7px 0 0;
  width: inherit;
}


.botoi-gorria {
  background: #ff0000;
  color: #fff;
}


.editable-input {
  .select2-container {
    width: 200px!important;
  }
}

.form-filters {
  margin-bottom: 80px;
}

.featured-1 {
  background: #008000;
  color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 3px;
}