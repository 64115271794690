.sidebar .sidebar-nav.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.sidebar .sidebar-search {
  padding: 15px;
}

.sidebar ul li {
  border-bottom: 1px solid #e7e7e7;
}

.sidebar ul li a.active {
  background-color: #eee;
}

.sidebar .arrow {
  float: right;
}

.sidebar .fa.arrow:before {
  content: "\f104";
}

.sidebar .active>a>.fa.arrow:before {
  content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
  padding-left: 37px;
}

.sidebar .nav-third-level li a {
  padding-left: 52px;
}

@media(min-width:768px) {
  .sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
    margin-top: 51px;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}