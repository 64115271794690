.notification-message {
  border-radius:4px;
  box-shadow: 0 1px 0 rgba(255,255,255,0.25) inset, 0 1px 2px rgba(0,0,0,0.05);
  margin-bottom: 5px;
  padding: 15px 20px 12px;
  color: black;
  font-weight: bold;
  position: absolute;
  text-shadow: 0 1px 0 rgba(255,255,255,0.2);
  top: 0px;
  width: 100%;
  z-index: 9999;
}